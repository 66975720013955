import { render, staticRenderFns } from "./industryData.vue?vue&type=template&id=a669474c&scoped=true&"
import script from "./industryData.vue?vue&type=script&lang=js&"
export * from "./industryData.vue?vue&type=script&lang=js&"
import style0 from "./industryData.vue?vue&type=style&index=0&id=a669474c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a669474c",
  null
  
)

export default component.exports